<template>
  <label
    class="cr-switch"
    :class="{ 'cr-switch--disabled': disabled }"
  >
    <input
      v-model="cValue"
      class="cr-switch__input"
      type="checkbox"
      :checked="isChecked"
      :disabled="disabled"
      :value="value"
      :true-value="trueValue"
      :false-value="falseValue"
      @input="onInput"
    />
    <span
      v-if="$slots.before"
      class="cr-switch__label cr-switch__label--before"
      :class="{ 'cr-switch__label--inactive': isChecked }"
    >
      <slot name="before" />
    </span>
    <i class="cr-switch__box" />
    <span
      v-if="$slots.default"
      :class="{ 'cr-switch__label--inactive': !isChecked }"
      class="cr-switch__label cr-switch__label--after"
      ><slot
    /></span>
  </label>
</template>

<script lang="ts" setup>
import { computed } from '#imports';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [Array, Boolean, String],
    default: undefined,
  },
  value: {
    type: [Boolean, Object, String, Number],
    default: null,
  },
  trueValue: {
    type: [Array, Boolean, String, Number],
    default: undefined,
  },
  falseValue: {
    type: [Array, Boolean, String, Number],
    default: undefined,
  },
  keyField: {
    type: String,
    default: null,
  },
  position: {
    type: String,
    default: 'left',
  },
});
const emit = defineEmits(['update:modelValue']);

function onInput(e: Event) {
  const cb = e.target as HTMLInputElement;
  let newValue;
  if (cb.checked) {
    newValue = Array.isArray(props.modelValue) ? [...props.modelValue, trueValue.value] : trueValue.value;
  } else if (props.keyField) {
    newValue = Array.isArray(props.modelValue)
      ? props.modelValue.filter((i) => i[props.keyField!] !== trueValue.value[props.keyField!])
      : props.falseValue;
  } else {
    newValue = Array.isArray(props.modelValue) ? props.modelValue.filter((i) => i !== trueValue.value) : props.falseValue;
  }
  cb.checked = isChecked.value;
  emit('update:modelValue', newValue);
}

const trueValue = computed(() => {
  return props.value || props.trueValue;
});
const cValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
const isChecked = computed(() => {
  if (props.keyField) {
    return Array.isArray(props.modelValue)
      ? props.modelValue.some((i) => i[props.keyField!] === trueValue.value[props.keyField!])
      : props.modelValue?.[props.keyField] === trueValue.value[props.keyField];
  } else {
    return Array.isArray(props.modelValue) ? props.modelValue.includes(trueValue.value) : props.modelValue === trueValue.value;
  }
});
</script>

<style lang="scss">
.cr-switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  line-height: 1;
  cursor: pointer;
  font-size: 16px;
  flex-shrink: 0;
  @include media-breakpoint-down(lg) {
    font-size: 14px;
  }
}

.cr-switch--disabled {
  cursor: not-allowed;
}

.cr-switch .cr-switch__box {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  border: 1px solid $new-line-stroke;
  background-color: $new-bg-theme-fade;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
  flex-shrink: 0;
}

.cr-switch .cr-switch__box::before {
  content: '';
  position: absolute;
  left: 0;
  width: 32px;
  height: 16px;
  background-color: $new-bg-theme-fade;
  border-radius: 11px;
  transform: translate3d(1px, 1px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.cr-switch .cr-switch__box::after {
  content: '';
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid $new-line-stroke;

  background-color: #fff;
  border-radius: 11px;
  //box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(1px, 1px, 0);
  transition: all 0.2s ease-in-out;
}

.cr-switch:not(.cr-switch--disabled):active .cr-switch__box::after {
  width: 28px;
  transform: translate3d(1px, 1px, 0);
}

.cr-switch:not(.cr-switch--disabled):active .cr-switch__input:checked ~ .cr-switch__box::after {
  transform: translate3d(10px, 1px, 0);
}

.cr-switch__label {
  transition: opacity 0.1s ease-in;
  user-select: none;
}

.cr-switch__label--inactive {
  opacity: 0.5;
}

.cr-switch__input {
  display: none;
}

.cr-switch__input:checked ~ .cr-switch__box {
  background-color: $new-fr-theme;
  border: 1px solid $new-fr-theme;
}

.cr-switch__input:checked ~ .cr-switch__box::before {
  transform: translate3d(17px, 1px, 0) scale3d(0, 0, 0);
}

.cr-switch__input:checked ~ .cr-switch__box::after {
  transform: translate3d(17px, 1px, 0);
}
</style>
